'use client';
import { PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/layout';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { setCookie } from 'nookies';
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import AwardAndPeerview from '@/app/_components/award-peerview/AwardAndPeerview';
import FancyCarousel from '@/app/_components/fancy-carousel/FancyCarousel';
import { ReactComponent as MapLocationIcon } from '@/app/_icons/CkMapIcon.svg';
import { COOKIE_NAME_DEALERSHIP_ID } from '@/constants/dealership/dealership.constants';
import { BlogListing } from '@/services/blog/BlogService';
import { setDealershipCookieOnUser } from '@/services/gtm.service';
import { formatDealerPhoneNumber } from '@/services/string-format.service';
import type {
  DealershipProps,
  DealershipSectionType,
  Times,
} from '@/types/dealership/dealership.types';
import type { BodyTypesGrouping } from '@/types/home/home.types';
import type { NewCarOverview } from '@/types/new-cars/new-cars.types';
import type { Special } from '@/types/specials/specials.types';

import BannerSection from './BannerSection';
import CTA from './CTA';
import styles from './DealershipPage.module.scss';
import MeetTheTeam from './MeetTheTeam';
import NewsFeed from './NewsFeed';
import PreOwnedSelection from './PreOwnedSelection';
import Promotions from './Promotion';
import TradeIn from './TradeIn';
import WarrantySection from './WarrantySection';

const DealershipMap = dynamic(() => import('./DealershipMap'));

const dayOrder = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const groupByTime = (data: DealershipProps['operatingHours']) => {
  const grouped = new Map();
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  data.forEach((entry) => {
    const dayName = dayNames.find((name) =>
      name.toLowerCase().startsWith(entry.day_of_the_week),
    );

    if (entry.openTime === 'closed') {
      if (!grouped.has('closed')) {
        grouped.set('closed', { days: [], closed: true });
      }
      grouped.get('closed').days.push(dayName);
    } else {
      const key = `${entry.openTime}-${entry.closeTime}`;

      if (!grouped.has(key)) {
        grouped.set(key, {
          days: [] as string[],
          openTime: entry.openTime,
          closeTime: entry.closeTime,
          closed: false,
        });
      }
      grouped.get(key).days.push(dayName);
    }
  });

  const OperatingHoursDisplay = (arr: Times[]) => {
    const orderedGroupedHours = dayOrder.flatMap((day) =>
      arr.flatMap((group) =>
        group.days.includes(day) ? [{ ...group, day }] : [],
      ),
    );

    return orderedGroupedHours;
  };

  return OperatingHoursDisplay(Array.from(grouped.values()));
};

type Props = {
  featuredPromotions: Special[];
  newCars: NewCarOverview[];
  dealerInfo: DealershipProps;
  body_types: BodyTypesGrouping[];
  dealerBlogs: BlogListing[] | null;
  carouselBackground: string | null;
  defaultImage: string;
};

const DealershipPage = ({
  featuredPromotions,
  newCars,
  dealerInfo,
  body_types,
  dealerBlogs,
  carouselBackground,
  defaultImage,
}: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [isMapInView, setIsMapInView] = useState(false);
  const groupedOperatingHours = groupByTime(dealerInfo?.operatingHours);
  const router = useRouter();

  const phoneNumberToOpen = formatDealerPhoneNumber(dealerInfo?.phoneNumber);

  const checkMapInView = useCallback(() => {
    if (mapRef != null && mapRef?.current) {
      const rect = mapRef.current.getBoundingClientRect();

      setIsMapInView((wasMapInView) => {
        // once it comes into view, we'll keep it set as true as we don't want to unload it
        return (
          wasMapInView || (rect.top < window.innerHeight && rect.bottom >= 0)
        );
      });
    }
  }, [mapRef]);

  useEffect(() => {
    checkMapInView();
    window.addEventListener('scroll', checkMapInView);

    // store this current dealer as the preferred dealer
    setCookie(null, COOKIE_NAME_DEALERSHIP_ID, dealerInfo.id, {
      maxAge: process.env.NEXT_PUBLIC_DEALERSHIP_COOKIE_AGE || 1800,
      path: '/',
    });

    setDealershipCookieOnUser();

    router.refresh();
    return () => {
      window.removeEventListener('scroll', checkMapInView);
    };
  }, []);

  const renderNode = (section: DealershipSectionType) => {
    switch (section) {
      case 'contact': {
        return (
          <Container
            key={section}
            maxW="100%"
            className={styles.mapWrapper}
            paddingX={{ base: '6', md: '14' }}
            paddingTop={{ base: '14', md: '50px' }}
            paddingBottom={{ base: 14, md: '50px' }}
          >
            <Suspense fallback={null}>
              <div className={styles.headingElement}>
                <Heading
                  as="h2"
                  className="titleLg"
                  textAlign={{ base: 'center', lg: 'start' }}
                  mb={45}
                >
                  Find Us
                </Heading>
              </div>
              <SimpleGrid
                columns={[1, 1, 2, 3]}
                maxWidth={{ base: '100%', md: '1440px' }}
                spacingX={['40px']}
                spacingY={['40px']}
                className={styles.mapContactDetails}
                margin={'auto'}
              >
                <Box ref={mapRef}>
                  {isMapInView && <DealershipMap dealerInfo={dealerInfo} />}
                </Box>

                <Flex direction={'row'} width={'100%'} align={'center'}>
                  <Box
                    className={styles.headingElement}
                    width={'100%'}
                    maxWidth={{ base: '100%', sm: '400px', md: '380px' }}
                  >
                    <Flex dir="row">
                      <Flex direction="column">
                        <Heading
                          as="h3"
                          className={styles.titleMd}
                          marginBottom={4}
                        >
                          Physical Address
                        </Heading>
                        <Link
                          href={`${dealerInfo?.google_address}`}
                          target="_blank"
                        >
                          <Flex dir="row" gap={3}>
                            <MapLocationIcon
                              className="locationIcon"
                              width={28}
                              height={28}
                            />
                            <Text
                              fontSize={'14px'}
                              lineHeight={'19.6px'}
                              marginBottom={6}
                            >
                              {dealerInfo?.mainAddress &&
                                `${dealerInfo?.mainAddress}, `}
                              {dealerInfo?.secondaryAddress &&
                                `${dealerInfo?.secondaryAddress}, `}
                              {dealerInfo?.landmark &&
                                `${dealerInfo?.landmark}, `}
                              {dealerInfo?.city?.province?.name &&
                                `${dealerInfo?.city?.province?.name}, `}
                              {dealerInfo?.code && `${dealerInfo?.code}`}
                            </Text>
                          </Flex>
                        </Link>
                        <Flex direction={'row'} width={'100%'}>
                          <Box
                            className={styles.headingElement}
                            width={'100%'}
                            maxWidth={{
                              base: '100%',
                              sm: '400px',
                              md: '236px',
                            }}
                          >
                            <Flex dir="row">
                              <Flex direction="column">
                                <Heading
                                  as="h3"
                                  className={styles.titleMd}
                                  marginBottom={4}
                                >
                                  Contact Numbers
                                </Heading>
                                <Flex
                                  dir="row"
                                  alignItems="center"
                                  gap={3}
                                  marginBottom={4}
                                >
                                  <PhoneIcon
                                    className="phoneIcon"
                                    width={'13px'}
                                    height={'13px'}
                                    marginRight={2}
                                  />
                                  <Link href={`tel: +27 ${phoneNumberToOpen}`}>
                                    <Text
                                      fontSize={'14px'}
                                      lineHeight={'19.6px'}
                                    >
                                      {dealerInfo?.phoneNumber}
                                    </Text>
                                  </Link>
                                </Flex>

                                <Flex
                                  direction={'row'}
                                  alignItems={'center'}
                                  justifyContent={{
                                    base: 'space-between',
                                    sm: 'space-evenly',
                                  }}
                                >
                                  <Text
                                    as={'b'}
                                    fontSize={'14px'}
                                    lineHeight={'19.6px'}
                                  >
                                    Emergency:
                                  </Text>
                                  <Link href={'tel: +27 86 154 2000'}>
                                    <Text
                                      fontSize={'14px'}
                                      lineHeight={'19.6px'}
                                      marginLeft={'auto'}
                                      mb={0}
                                    >
                                      086 154 2000
                                    </Text>
                                  </Link>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
                <Flex direction={'row'} width={'100%'} align={'center'}>
                  <Box
                    className={styles.headingElement}
                    width={'100%'}
                    maxWidth={{ base: '100%', sm: '400px', md: '340px' }}
                  >
                    <Flex>
                      <Divider
                        orientation="vertical"
                        borderLeftWidth={'2px'}
                        marginRight={{ base: 4, sm: 6 }}
                        className={styles.borderLeft}
                      />
                      <Flex direction="column" width="100%">
                        <Heading
                          as="h3"
                          className={styles.titleMd}
                          marginBottom={4}
                        >
                          Showroom Hours
                        </Heading>
                        {groupedOperatingHours?.map((item, index) => (
                          <Flex
                            key={index}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={{
                              base: 'space-between',
                              sm: 'space-evenly',
                            }}
                            mb={2}
                          >
                            <Text
                              as={'b'}
                              fontSize={'14px'}
                              lineHeight={'19.6px'}
                            >
                              {item.day}
                            </Text>
                            {!item.closed && (
                              <Text
                                fontSize={'14px'}
                                lineHeight={'19.6px'}
                                marginLeft={'auto'}
                              >
                                {item.openTime} - {item.closeTime}
                              </Text>
                            )}
                            {item.closed && (
                              <Text
                                fontSize={'14px'}
                                lineHeight={'19.6px'}
                                marginLeft={'auto'}
                              >
                                Closed
                              </Text>
                            )}
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </SimpleGrid>
            </Suspense>
          </Container>
        );
      }
      case 'dealership_description': {
        if (!dealerInfo?.description) return null;
        return (
          <Container
            key={section}
            maxW="100%"
            className={styles.mapWrapper}
            paddingX={{ base: '6', md: '14' }}
            paddingTop={{ base: '14', md: '12px' }}
            paddingBottom={{ base: '14', md: '14' }}
          >
            <Box
              maxWidth={'100%'}
              dangerouslySetInnerHTML={{ __html: dealerInfo?.description }}
              margin={'auto'}
            ></Box>
          </Container>
        );
      }
      case 'meet_the_team': {
        if (!dealerInfo?.dealership_team || !dealerInfo?.dealership_team.length)
          return null;
        return (
          <MeetTheTeam
            key={section}
            teams={dealerInfo?.dealership_team}
            defaultImage={defaultImage}
          />
        );
      }
      case 'new_vehicles_slider': {
        return (
          <FancyCarousel
            key={section}
            imageHrefBase="/new-cars"
            isDealershipPage
            carsWithOverview={newCars}
            body_types={body_types}
            new_cars_background={carouselBackground ?? ''}
          />
        );
      }
      case 'promotions': {
        if (!featuredPromotions || !featuredPromotions.length) return null;
        return (
          <Promotions
            key={section}
            featuredItems={featuredPromotions}
            dealershipName={dealerInfo?.name}
          />
        );
      }
      case 'pre_owned_call_to_action': {
        if (
          !dealerInfo?.pre_owned_call_to_action ||
          !dealerInfo?.pre_owned_call_to_action?.image
        )
          return null;
        return (
          <PreOwnedSelection
            key={section}
            pre_owned_call_to_action={dealerInfo?.pre_owned_call_to_action}
          />
        );
      }
      case 'trust': {
        const awards = dealerInfo?.awards || [];
        const warranties = dealerInfo?.warranties || [];

        if (!awards.length && !warranties.length) {
          return null;
        }
        return (
          <Fragment key={section}>
            {(awards?.length > 0 || dealerInfo.peerview) && (
              <AwardAndPeerview
                awards={awards}
                heading={dealerInfo?.dealership_awards_title}
                peerview={dealerInfo.peerview}
              />
            )}
            <WarrantySection
              warranty_title={warranties[0]?.warranties_id?.title}
              warranty_description={
                warranties[0]?.warranties_id?.description ?? ''
              }
              warranty_image={warranties[0]?.warranties_id?.image}
            />
          </Fragment>
        );
      }
      case 'cta_section': {
        if (!dealerInfo?.call_to_actions || !dealerInfo?.call_to_actions.length)
          return null;
        return (
          <CTA key={section} call_to_actions={dealerInfo?.call_to_actions} />
        );
      }
      case 'trade_in_call_to_action': {
        if (!dealerInfo?.trade_in_call_to_action?.image) return null;
        return (
          <TradeIn
            key={section}
            trade_in_call_to_action={dealerInfo?.trade_in_call_to_action}
          />
        );
      }
      case 'news_feed': {
        if (!dealerBlogs || !dealerBlogs.length) return null;
        return <NewsFeed key={section} dealerBlogs={dealerBlogs} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <Box
        className={cn(
          styles.dealershipHeroBanner,
          dealerInfo?.header_call_to_actions.length === 0 &&
            styles.dealershipHeroBannerNoCTA,
        )}
      >
        {dealerInfo?.name && dealerInfo?.mainImage && (
          <BannerSection
            dealerInfo={dealerInfo}
            name={dealerInfo?.name}
            mainImage={dealerInfo?.mainImage ? dealerInfo?.mainImage : ''}
          />
        )}
      </Box>
      {dealerInfo?.section_order?.length > 0 &&
        dealerInfo?.section_order?.map(({ section }) => renderNode(section))}
    </>
  );
};

export default DealershipPage;
